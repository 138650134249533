<template>
  <div class="leading-normal tracking-normal" id="main-body">
 
          <Dashboard v-if="routeName=='Admin'"/>
          <Sidebar />
          <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Sidebar from '@/components/admin/Sidebar'
//import Navbar from '@/components/admin/Navbar'
//import Footer from '@/components/admin/Footer'
import Dashboard from '@/components/admin/Dashboard'



export default {
  
  
  components: {
    Dashboard,
    Sidebar,
    //Navbar,
    //Footer
  },
  data(){
    return{
      user2:"Gomez",
      routeName: "",
    }
  },
created() {
    this.routeName = this.$route.name;
   
  },
  computed: {
    ...mapState(['sideBarOpen']),
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted(){
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  }
  
}
</script>